import { useEffect, useState, type FC } from 'react'
import { API } from '@/lib/api'
import type { TermItem } from 'models/termItem'

export interface ISearchHeaderProps {
  isOpen: boolean
  onClose: () => void
}

export const SearchHeader: FC<ISearchHeaderProps> = ({ isOpen, onClose }) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [termData, setTermData] = useState<TermItem[]>([])
  const [includeDescription, setIncludeDescription] = useState<boolean>(true)
  const [visibleCount, setVisibleCount] = useState<number>(10)

  useEffect(() => {
    const getData = async () => {
      const res = await API.getTerms()
      setTermData(res.data)
    }

    getData()
  }, [])

  useEffect(() => {
    setVisibleCount(10)
  }, [searchValue, includeDescription])

  const getTerms = () => {
    if (termData) {
      return termData?.filter((term: TermItem) => {
        let isShow = false

        if (searchValue) {
          isShow =
            term.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
            (includeDescription &&
              term.description.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
        }

        return isShow
      })
    } else {
      return []
    }
  }

  const loadMore = () => {
    setVisibleCount(prevCount => prevCount + 10)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeDescription(event.target.checked)
  }

  const getTermsRender = () => {
    return getTerms()
      .slice(0, visibleCount)
      .map((term: TermItem, index: number) => {
        return (
          <div role="listitem" className="w-dyn-item" key={index}>
            <a href={`/glossary/${term.slug}`} className="listing-link w-inline-block">
              <div className="content-listing-card" style={{ backgroundColor: term.colorCode }}>
                <div
                  id="w-node-_6950ab59-a50a-96e0-668e-0ebb5626b86e-09f1eb78"
                  className="heading-set-2"
                >
                  <div className="heading-set-3">
                    <h1 className="listing-card-heading">{term.name}</h1>
                    <p className="paragraph-regular">{term.description}</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        )
      })
  }

  const renderPaginationSummary = () => {
    const totalResults = getTerms().length
    const start = totalResults > 0 ? 1 : 0
    const end = Math.min(visibleCount, totalResults)

    return <span className="results">{`${start}-${end}`}</span>
  }

  return (
    <div className="invest-area" style={{ display: isOpen ? 'block' : 'none' }}>
      <div className="invest-area-wrapper">
        <div className="invest-area-box search" style={{ display: isOpen ? 'block' : 'none' }}>
          <div className="form">
            <img
              src="/images/icon-search-button.svg"
              className="search-button"
              alt="Search Button"
            />
            <input
              name="ctl00$InvestSearch$SearchInput"
              type="text"
              placeholder="Search on Glossary"
              value={searchValue}
              onChange={event => {
                setSearchValue(event.target.value)
              }}
            />
            <button id="ctl00_InvestSearch_SearchInputButton" className="submit" type="button">
              <i className="icon-search"></i>
            </button>
          </div>
        </div>
        <div
          className={`search-list-container  ${searchValue && searchValue.length > 0 ? 'searched' : ''}`}
        >
          <div className="container">
            <div className="row">
              <div className="gloosary-list-wrapper jetboost-list-wrapper-z8g7 jetboost-list-wrapper-kwl7 w-dyn-list">
                {getTerms().length > 0 && (
                  <div className="result-summary-container">
                    <span className="results">{`${getTerms().length} results`}</span>
                    <div className="toggle-container">
                      <label className="toggle-label" htmlFor="include-description">
                        Include Description
                      </label>
                      <input
                        type="checkbox"
                        id="include-description"
                        checked={includeDescription}
                        onChange={handleCheckboxChange}
                        className="toggle-input"
                      />
                    </div>
                  </div>
                )}
                <div role="list" className="glossary-listing w-dyn-items">
                  {getTermsRender()}
                </div>
                {visibleCount < getTerms().length && (
                  <div className="show-more-container">
                    {renderPaginationSummary()}
                    <button className="show-more-button fdi-button-shadow" onClick={loadMore}>
                      Show More
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="close">
          <a
            role="presentation"
            className="invest-close-button fdi-button-shadow"
            onClick={() => {
              setSearchValue('')
              onClose()
            }}
          >
            <img
              className="close-button-img"
              src="/images/icon-close-button.svg"
              alt="Close Button"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

import { APIError, type BaseResponse } from 'models/baseResponse'
import type { LetterItem } from 'models/letterItem'
import type { TermItem } from 'models/termItem'
import config from '../../config/config'

export class API {
  static prefix = '_vti_bin/InvestAPI/GlossaryService.svc'
  static webServiceUrl = config.getWebServiceUrl()

  private static async post(method: string, pageOffset: number = 1, pageSize: number = 1000) {
    let resp
    try {
      resp = await fetch(
        `${API.webServiceUrl}/${API.prefix}/${method}?pageOffset=${pageOffset}&pageSize=${pageSize}`,
        {
          method: 'POST',
        }
      )
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }
    if (resp.status !== 200) {
      const { error } = await resp.json()
      throw new APIError({ status: resp.status, message: error, code: 'INTERNAL' })
    }
    return await resp.json()
  }

  static async getTerms(): Promise<BaseResponse<TermItem>> {
    let result: BaseResponse<TermItem>

    try {
      result = await this.post('GetTerms')
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }

    return await result
  }

  static async getLetters(): Promise<BaseResponse<LetterItem>> {
    let result: BaseResponse<LetterItem>

    try {
      result = await this.post('GetLetters')
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }

    return await result
  }
}

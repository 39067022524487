import Link from 'next/link'
import type { FC } from 'react'

export const MainFooter: FC = () => {
  return (
    <footer className="section-footer">
      <div className="container-large">
        <div className="footer-wrapper">
          <div className="footer-logo-wrapper">
            <img
              src="/images/FDI-Glossary-Background-1.svg"
              loading="lazy"
              alt=""
              className="footer-logo"
            />
            <a target="_blank" href="https://invest.gov.tr/" rel="noreferrer">
              <img
                src="/images/cbyo-logo.png"
                loading="lazy"
                alt=""
                className="footer-logo cbyo-logo"
              />
            </a>
          </div>
          <img
            src="/images/Invest-in-Turkiye-Frame.svg"
            loading="lazy"
            alt=""
            className="footer-logo"
          />
          <p className="paragraph-footer">
            The Foreign Direct Investment Glossary covers over 240 FDI terms, from financial
            concepts to policy tools and trade agreements, offering a broad understanding of FDI.
          </p>
          <div className="footer-link-wrapper">
            <Link href="/list" className="footer-link w-inline-block">
              <div className="nav-button light">FDI TERMS LIST</div>
            </Link>
            <Link href="/preface" className="footer-link w-inline-block">
              <div className="nav-button light">PREFACE</div>
            </Link>
            <Link href="https://invest.gov.tr" className="footer-link w-inline-block">
              <div className="nav-button light">INVEST.GOV.TR</div>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

import Link from 'next/link'
import { type FC } from 'react'

export interface IMainHeaderProps {
  onSearchOpen: () => void
}

export const MainHeader: FC<IMainHeaderProps> = ({ onSearchOpen }) => {
  // const { t } = useTranslation(homeConfig.i18nNamespaces)

  return (
    <div
      data-w-id="f2bce151-16aa-64cb-e8f6-a484633dba68"
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav"
    >
      <div className="navbar-container">
        <div className="brand w-nav-brand w--current">
          <Link href="/" aria-current="page">
            <img
              src="/images/FDI-Glossary-Logo.svg"
              loading="lazy"
              width="159"
              height="58.41712951660156"
              alt=""
              className="vectors-wrapper"
            />
          </Link>
          <a target="_blank" href="https://invest.gov.tr/" rel="noreferrer">
            <img
              src="/images/Investment-Office-SVG-Logo.svg"
              loading="lazy"
              alt=""
              className="investment-office-logo"
            />
          </a>
        </div>

        <div className="navlinks">
          <Link href="/list" className="nav-button w-nav-link fdi-term-list fdi-button-shadow">
            FDI Terms List
          </Link>
          <span
            className="search nav-button fdi-button-shadow"
            role="presentation"
            onClick={() => {
              onSearchOpen()
            }}
          >
            <img src="/images/icons8-search.svg" loading="lazy" alt="" className="icons-search" />
          </span>
        </div>
      </div>
    </div>
  )
}

import { useState, type FC, type ReactNode } from 'react'
import { MainFooter } from '@/components/layout/MainFooter'
import { MainHeader } from './MainHeader'
import { SearchHeader } from './SearchHeader'

export const MainLayout: FC<{ bodyClass?: string; children: ReactNode }> = props => {
  const { children } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function setHtmlOverflow(hidden: boolean): void {
    const htmlElement = document.documentElement

    if (hidden) {
      // Eski overflow değerini sakla
      htmlElement.dataset.previousOverflow = htmlElement.style.overflow
      // Overflow'u hidden yap
      htmlElement.style.overflow = 'hidden'
    } else {
      // Eski overflow değerini geri yükle
      if (htmlElement.dataset.previousOverflow !== undefined) {
        htmlElement.style.overflow = htmlElement.dataset.previousOverflow
      } else {
        // Eğer eski değer yoksa varsayılan değeri boş bırak
        htmlElement.style.overflow = ''
      }
    }
  }

  return (
    <div className="main-container">
      <div className="main-wrapper">
        <MainHeader
          onSearchOpen={() => {
            setIsOpen(true)
            setHtmlOverflow(true)
          }}
        />
        {children}
        <MainFooter />
      </div>
      <SearchHeader
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
          setHtmlOverflow(false)
        }}
      ></SearchHeader>
    </div>
  )
}
